@tailwind base;
@tailwind components;
@tailwind utilities;


/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
 */
@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
    a {
        @apply cursor-pointer;
    }
}

/* fonts */
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Raleway'), url('assets/fonts/Raleway-Regular.woff2') format('woff2');
}
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Raleway'), url('assets/fonts/Raleway-Medium.woff2') format('woff2');
}
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Raleway'), url('assets/fonts/Raleway-Bold.woff2') format('woff2');
}



.flip-card {
    perspective: 1000px;
}
.flip-card-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    -moz-backface-visibility: hidden;
}
.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner{
    transform: rotateY(180deg);
}
.flip-card-front {
    backface-visibility: hidden;
}
.flip-card-back {
    transform: rotateY(180deg);
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}
.us::before {
    content:'';
    background-image: url('./assets/en.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 15px;
    display: inline-block;
}
.de::before {
    content:'';
    background-image: url('./assets/de.svg');
    background-repeat: no-repeat;
    width: 35px;
    height: 17px;
    display: inline-block;
}

  .hamburger-icon.open:before {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger-icon.open:after {
    transform: translateY(-8px) rotate(-45deg);
  }
  .hamburger-icon.open div {
    transform: scale(0);
  }
  